import { Component, Prop } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'
import axios from 'axios'

const { VUE_APP_WEB_URL, VUE_APP_BASE_URL, VUE_APP_USE_TEMPLATE } = process.env

@Component
export class StockDetailView extends GForm {
  @Prop({ type: Object, default: () => ({}) }) documents!: Record<string, any>

  async copyVehicleToClipboard (stock, files) {
    const car = await this.fetchData({
      query: { model: 'Car', name: 'find' },
      filter: { auto: { id: { _eq: stock.auto.id } } },
    })

    if (!car.length) return

    const validFiles = Object.entries(files)
      .filter(([key, value]: [key: string, value: Record<string, any>]) => this.documents[key] && value?.photo?.[0]?.file?.uri)
      .map(([key, value]: [key: string, value: Record<string, any>]) => {
        const link = value?.photo?.[0]?.file.uri
        return `${key}=${encodeURIComponent(link as string)}`
      }) // Encodificar cada valor para la URL
      .join('&')

    const url = `${VUE_APP_WEB_URL}${car[0].id}?${validFiles}`

    if (VUE_APP_USE_TEMPLATE === 'true') {
      try {
        const response = await axios.post(`${VUE_APP_BASE_URL}/convert-pdf-to-image/shorten`, { url })

        const shortUrl = `${VUE_APP_WEB_URL.replace('/stock', '')}u/${response.data.short_url}`
        await navigator.clipboard.writeText(shortUrl)
      } catch (error) {
        console.error('Error shortening URL:', error)
      }
    } else {
      await navigator.clipboard.writeText(url)
    }
  }

  goToOfficialSite (auto) {
    if (!auto?.officialSite) return null
    window.open(auto?.officialSite, '_blank')
  }
}
